import React, {FC, PropsWithChildren, ReactNode} from 'react';
import classNames from 'classnames';

interface MarketFlashHeaderProps {
  children: ReactNode;
  className?: string;
}

export const MarketFlashHeader: FC<PropsWithChildren<MarketFlashHeaderProps>> = ({
  children,
  className,
}) => {
  return (
    <div>
      <h2 className={classNames('market-flash-banner__heading', className)}>{children}</h2>
    </div>
  );
};

interface MarketFlashBodyProps {
  title: ReactNode;
  className?: string;
}

export const MarketFlashBody: FC<PropsWithChildren<MarketFlashBodyProps>> = ({
  title,
  className,
}) => {
  return <div className={classNames('market-flash-banner__body', className)}>{title}</div>;
};

interface MarketFlashProps {
  children: ReactNode;
  onDismiss?: () => void;
}

export const MarketFlash: FC<PropsWithChildren<MarketFlashProps>> = ({onDismiss, children}) => {
  return (
    <div className="market-flash-banner">
      {onDismiss ? (
        <div className="market-flash-banner__close_button" onClick={onDismiss}>
          <i className="icon icon-thin-x" />
        </div>
      ) : null}
      {children}
    </div>
  );
};

interface MarketFlashInfoProps {
  info: string[];
}

export const MarketFlashInfo: FC<PropsWithChildren<MarketFlashInfoProps>> = ({info}) => {
  const steps = info.map((step, i) => {
    return <li key={`flash-info-step-${i.toString()}`}>{step}</li>;
  });
  return (
    <div className="market-flash-banner__info">
      <ul>{steps}</ul>
    </div>
  );
};
