import React from 'react';

import Banner from 'web/components/banner';

const ThanksgivingBanner = () => {
  let bannerText;
  const gotoLink = '/thanksgiving';
  if (typeof window !== 'undefined') {
    const urlParams = new URLSearchParams(window.location.search);
    const testDate = urlParams.get('timestamp');
    const now = testDate ? new Date(testDate) : new Date();
    if (now >= new Date('10/27/2023') && now <= new Date('11/20/2023')) {
      if (window.location.pathname === '/thanksgiving') {
        return;
      }
      bannerText = (
        <p data-testid="membership-banner-text">
          Enjoy FREE delivery on orders delivered through November 19th!
        </p>
      );
    }
  }

  if (!bannerText) {
    return;
  }
  return (
    <div className="basket-view__header thanksgiving-banner">
      <Banner
        onClickInfo={() => {
          window.location = gotoLink;
        }}
        title={bannerText}
      />
    </div>
  );
};

export default ThanksgivingBanner;
