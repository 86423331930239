import update from 'immutability-helper';

export const RENDER_PRODUCTS = 'PRODUCT_LISTINGS_RENDER_PRODUCTS';
export const LAZY_PRODUCTS_HAVE_LANDED = 'PRODUCT_LISTINGS_LAZY_PRODUCTS_HAVE_LANDED';

export const actions = {
  renderProducts: (productIds) => ({type: RENDER_PRODUCTS, productIds}),
};

export function reducer(state, action) {
  switch (action.type) {
    case LAZY_PRODUCTS_HAVE_LANDED:
      state = update(state, {lazyProducts: {$set: action.lazyProducts}});
      break;

    case RENDER_PRODUCTS:
      state = update(state, {
        products: {
          $merge: action.productIds.reduce((memo, productId) => {
            memo[productId] = state.lazyProducts[productId];
            return memo;
          }, {}),
        },
      });
      break;
  }

  return state;
}
