import React, {useState, FormEvent, FC} from 'react';
import {email as validateEmail} from 'goodeggs-validators';
import classNames from 'classnames';
import {connect} from 'react-redux';

import Input from 'web/components/input';
import SubmitButton from 'web/components/submit_button';

interface Event {
  target: {
    value: string;
  };
}

interface Props {
  user?: {
    masquerading: boolean;
    email: string;
  };
}

const AlcoholComingSoonForm: FC<Props> = ({user}) => {
  const [email, setEmail] = useState(user?.email || '');
  const [isEmailProvided, setIsEmailProvided] = useState(false);
  const buttonClassName = !validateEmail(email) ? 'acs--disabled-button' : 'acs--active-button';

  const handleChange = (e: Event): void => setEmail(e.target.value);
  const onSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    const emailCapturedProps = {
      email,
      formName: 'alcohol',
      loggedIn: Boolean(user),
      masquerading: user?.masquerading ?? false,
    };

    window.metrics.track('Email Captured', emailCapturedProps, false);
    setIsEmailProvided(true);
  };

  return (
    <form className="acs--form" onSubmit={onSubmit} data-testid={'alcohol-coming-soon-form'}>
      {!isEmailProvided ? (
        <>
          <p className="acs--title">
            We&apos;re still working to make alcohol available for delivery in your area.
          </p>
          <p className="acs--message">Be the first to know once it has arrived!</p>
          <Input
            type="email"
            placeholder="Email"
            onChange={handleChange}
            className="acs--email-input"
            value={email}
          />
          <SubmitButton
            disabled={!validateEmail(email)}
            className={classNames('acs--button', buttonClassName)}
          >
            Notify Me
          </SubmitButton>
        </>
      ) : (
        <p className="acs--title">
          Thank you. You&apos;ll receive an email when alcohol is available for delivery in your
          area.
        </p>
      )}
    </form>
  );
};

const mapStateToProps = (state: Props): Props => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(AlcoholComingSoonForm);
