import React, {FC, PropsWithChildren} from 'react';

import {
  MarketFlash,
  MarketFlashHeader,
  MarketFlashBody,
  MarketFlashInfo,
} from 'web/components/market_flash';

interface PreorderPeriodProps {
  announcement: {
    title: string;
    body: string;
    cta: string;
    info?: string[];
  };
  categoryId?: string;
}

interface PreorderAnnouncementProps {
  preorderPeriod: PreorderPeriodProps;
  onClickCTA?: (preorderPeriod: PreorderPeriodProps) => void;
  shouldRedirect: boolean;
}

const PreorderAnnouncement: FC<PropsWithChildren<PreorderAnnouncementProps>> = ({
  preorderPeriod,
  onClickCTA,
  shouldRedirect,
}) => {
  const {announcement} = preorderPeriod;
  const clickCallToAction = (): void => {
    if (shouldRedirect && preorderPeriod.categoryId) {
      window.location.href = preorderPeriod.categoryId;
      return;
    }
    onClickCTA?.(preorderPeriod);
  };

  return (
    <div className="preorder-announcement" data-testid="preorder-announcement">
      <MarketFlash>
        <MarketFlashHeader>{announcement.title}</MarketFlashHeader>
        <MarketFlashBody
          title={
            <p>
              {announcement.body} <a onClick={clickCallToAction}>{announcement.cta}</a>
            </p>
          }
        >
          {announcement.info && announcement.info.length > 0 && (
            <MarketFlashInfo info={announcement.info} />
          )}
        </MarketFlashBody>
      </MarketFlash>
    </div>
  );
};

export default PreorderAnnouncement;
